import styled from "@emotion/styled";
import { TextField } from "new-components";
import { theme } from "theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e9e9ed;
  border-top: 1px solid #e9e9ed;
  height: 32px;
  padding-left: var(--spacing-sm);
  padding-right: var(--spacing-sm);
  gap: var(--spacing-sm);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-left: ${theme.spacing.xs};
`;

export const StyledControlledTextField = styled(TextField)`
  font-weight: ${theme.font.weight.semiBold};
  font-size: ${theme.font.size.body};
  background-color: transparent;
`;
