import {
  ContractTransferRequestDTO,
  OrganizationNewService,
  TeamService,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useUserInfo } from "../../../hooks";

export const useOrganizationTeamsQuery = (id?: string) => {
  return useQuery({
    queryKey: ["organizations", id, "teams"],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return await OrganizationNewService.getTeams(id!);
    },
    enabled: !!id,
  });
};

export const useTransferContractsMutation = (organizationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      sourceTeamId: string;
      targetTeamId: string;
      contracts: ContractTransferRequestDTO;
    }) => {
      return OrganizationNewService.transferContracts(
        organizationId,
        data.sourceTeamId,
        data.targetTeamId,
        data.contracts
      );
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["contracts"],
      });
    },
  });
};

export const useTeamDeleteMutation = () => {
  const { refreshUserInfo } = useUserInfo();

  return useMutation({
    mutationFn: (data: { teamId: string }) => {
      return TeamService.deleteTeam(data.teamId);
    },
    onSuccess: async () => {
      await refreshUserInfo();
    },
  });
};
