import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  border-bottom: 1px solid ${theme.color.gray[300]};
  background-color: ${theme.color.white};
  padding: ${theme.spacing.sm};
  gap: ${theme.spacing.xs};
  position: sticky;
  top: 0;
  z-index: 2;
  overflow: hidden;
`;

export const paperStyles = {
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.color.white,
  padding: theme.spacing.sm,
  maxHeight: "36px",
  width: "auto",
  maxWidth: "100%",
  marginTop: theme.spacing.md,
  borderRadius: theme.borderRadius,
  boxShadow: "var(--dropdown-shadow)",
};

export const MoreMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  gap: ${theme.spacing.xs};
`;
