import React, { useState, useEffect } from "react";
import { getInitials } from "contexts/team/helpers";
import { IconButton, List } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import { TeamDescriptionDto } from "openapi";
import { CanAccessTeam } from "shared/service/authService";
import { theme } from "theme";
import { findParentId } from "./helpers";
import { Icon } from "../../styles";
import { ListItemButtonWrapper, ListItemButton } from "./styles";

export type ExtendedTeamDescriptionDto = TeamDescriptionDto & {
  parent?: boolean;
};

type Props = {
  organizationsAndTeams: TeamDescriptionDto[];
  selectedTeamId: string;
  handleSelectTeam: (id: string) => void;
  handleClose: () => void;
  defaultOpenItemId: string;
};

const Dropdown = ({
  organizationsAndTeams,
  selectedTeamId,
  handleSelectTeam,
  handleClose,
  defaultOpenItemId,
}: Props) => {
  const [openItems, setOpenItems] = useState<string[]>([]);

  useEffect(() => {
    const parentIdOfSelectedTeam = findParentId(
      organizationsAndTeams,
      selectedTeamId
    );
    if (parentIdOfSelectedTeam) setOpenItems([parentIdOfSelectedTeam]);
  }, [organizationsAndTeams, selectedTeamId]);

  useEffect(() => {
    if (defaultOpenItemId) {
      setOpenItems((prevOpenItems) =>
        prevOpenItems.includes(defaultOpenItemId)
          ? prevOpenItems
          : [...prevOpenItems, defaultOpenItemId]
      );
    }
  }, [defaultOpenItemId]);

  const handleToggleClick = (itemId: string) => {
    if (openItems.includes(itemId)) {
      setOpenItems(openItems.filter((id) => id !== itemId));
    } else {
      setOpenItems([...openItems, itemId]);
    }
  };

  const handleSelectClick = (itemId: string) => {
    handleSelectTeam(itemId);
    handleClose();
  };

  const renderListItemButton = (
    item: TeamDescriptionDto,
    active: boolean,
    level: number
  ) => (
    <ListItemButton
      aria-label={item.name.toLowerCase().replace(" ", "-")}
      onClick={() => handleSelectClick(item.id)}
      active={!active}
      main={level === 1}
    >
      <Icon visible>
        <span>{getInitials(item.name)}</span>
      </Icon>

      {!CanAccessTeam(item) && (
        <WarningIcon
          sx={{
            margin: "0 .25rem",
            fontSize: "1rem",
            color: theme.color.orange[600],
          }}
        />
      )}

      {item.name}
    </ListItemButton>
  );

  const renderToggleButton = (item: TeamDescriptionDto, isOpen: boolean) => (
    <IconButton
      onClick={() => handleToggleClick(item.id)}
      sx={{ width: "40px", height: "40px" }}
    >
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
  );

  const renderMenuItems = (items: ExtendedTeamDescriptionDto[], level = 1) => {
    const itemsSorted = items.sort((a, b) => a.name.localeCompare(b.name));
    return itemsSorted.map((item) => {
      const isOpen = openItems.includes(item.id);
      const hasChildren = item.children && item.children.length > 0;
      const isOptionActive = item.id === selectedTeamId;
      item.parent = hasChildren;

      return (
        <React.Fragment key={item.id}>
          <ListItemButtonWrapper>
            {renderListItemButton(item, isOptionActive, level)}
            {hasChildren && renderToggleButton(item, isOpen)}
          </ListItemButtonWrapper>

          {hasChildren && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List>{renderMenuItems(item.children, level + 1)}</List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return <List>{renderMenuItems(organizationsAndTeams)}</List>;
};

export default Dropdown;
