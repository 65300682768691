import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { ImagePreview, Overlay, NoContent } from "./styles";
import { ContractTemplateService } from "openapi";
import { CircularProgress } from "@mui/material";

const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 10;

const Preview = ({
  onClick,
  templateId,
  organizationId,
}: {
  templateId: string;
  organizationId: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState<Blob | undefined>(undefined);
  const [fileURL, setFileURL] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (templateId) {
      const loadImage = async (retryDelay: number) => {
        const retry = (retryDelay: number) => {
          const nextRetryDelay = retryDelay * 2;
          setRetryCount((prevRetryCount) => prevRetryCount + 1);
          setTimeout(async () => {
            await loadImage(nextRetryDelay);
          }, nextRetryDelay);
        };

        setLoading(true);
        try {
          const blob = (await ContractTemplateService.getImage(
            templateId,
            organizationId
          )) as Blob;
          if (!blob) {
            retry(retryDelay);
          }
          setImageFile(blob);
          setRetryCount(0);
        } catch (error) {
          if (retryCount < MAX_RETRIES) {
            retry(retryDelay);
          } else {
            console.error("Error fetching templaye preview image:", error);
            setLoading(false);
          }
        } finally {
          if (imageFile === undefined) {
            setLoading(false);
          }
        }
      };

      void loadImage(INITIAL_RETRY_DELAY);
    }
  }, [templateId]);

  useEffect(() => {
    if (!imageFile) {
      return;
    }
    const file = new Blob([imageFile], { type: "image/png" });
    setFileURL(URL.createObjectURL(file));
    setLoading(false);

    return () => {
      if (fileURL) {
        setFileURL(null);
        URL.revokeObjectURL(fileURL);
      }
    };
  }, [imageFile]);

  return (
    <ImagePreview onClick={onClick} role="button">
      {loading ? (
        <CircularProgress />
      ) : fileURL ? (
        <img src={fileURL} alt="template-preview" />
      ) : (
        <NoContent>
          <span>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.templateCard.noContent"
            )}
          </span>
        </NoContent>
      )}
      <Overlay />
    </ImagePreview>
  );
};

export default memo(Preview);
