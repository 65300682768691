import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import type { Block } from "aws-sdk/clients/textract";
import { ContractDTOV1, DocumentDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { convertContractName } from "constants/utils";
import { ContractWrapper } from "./styles";
import { PDFViewer } from "components/PDFViewer/PDFViewer";
import { useParams } from "react-router-dom";
import { useDownloadDocumentQuery } from "shared/api";
import { useDocumentOcrResultQuery } from "shared/api/documents";

type Props = {
  contractData?: ContractDTOV1;
  selectedDocument?: DocumentDTO;
  temporaryPdf?: Blob;
};

const ContractPDF = ({
  selectedDocument,
  contractData,
  temporaryPdf,
}: Props) => {
  const { organizationId } = useTeam();
  const [fileURL, setFileURL] = useState("");
  const [documentOcrResult, setDocumentOcrResult] = useState<Record<
    string,
    unknown
  > | null>(null);
  const { id: contractId } = useParams();

  const { data: documentFile, isLoading: isfileLoading } =
    useDownloadDocumentQuery(
      organizationId,
      contractData?.teamId,
      contractId,
      selectedDocument?.id
    );

  const { data: documentOrcResult, isLoading: isOcrResultLoading } =
    useDocumentOcrResultQuery(
      organizationId,
      contractData?.teamId,
      contractId,
      selectedDocument?.id
    );

  useEffect(() => {
    const content = temporaryPdf || documentFile;
    if (!content) {
      return;
    }

    const file = new Blob([content], { type: documentFile?.type });
    setFileURL(`${URL.createObjectURL(file)}#zoom=100`);

    return () => {
      URL.revokeObjectURL(fileURL);
      setFileURL("");
    };
  }, [documentFile, contractId, selectedDocument, temporaryPdf]);

  useEffect(() => {
    if (!selectedDocument || !documentOrcResult) {
      return;
    }
    //TODO: check why is this changed
    const ocr =
      typeof documentOrcResult === "string"
        ? (JSON.parse(documentOrcResult as unknown as string) as Record<
            string,
            unknown
          >)
        : documentOrcResult;

    setDocumentOcrResult(ocr);
  }, [documentOrcResult, contractId, selectedDocument]);

  if (!temporaryPdf && (isOcrResultLoading || isfileLoading))
    return <CircularProgress sx={{ margin: "auto" }} />;

  const fileName = selectedDocument
    ? selectedDocument.filename
    : convertContractName(contractData?.name || "");

  return (
    <ContractWrapper>
      <PDFViewer
        fileURL={fileURL}
        fileName={fileName}
        textractBlocks={(documentOcrResult || []) as Block[]}
      />
    </ContractWrapper>
  );
};

export default ContractPDF;
