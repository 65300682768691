import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  ContractAttachmentListItemDTO,
  ContractAttachmentService,
  ContractDTOV1,
  ContractNameDto,
  DocumentDTO,
  DocumentService,
  OrganizationService,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import VerifyAllBanner from "./components/VerifyAllBanner/VerifyAllBanner";
import routePaths from "constants/routePaths";
import { Features } from "constants/";
import RightSide from "./components/RightSide/RightSide";
import { PrintProvider } from "contexts/contract/context";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { useFieldsQuery } from "shared/api/fields";
import CircularLoading from "components/CircularLoading/CircularLoading";
import ContractData from "./components/ContractData/ContractData";
import { Header, Toolbar } from "./components";
import { ContractGrid, ContractColumn } from "./styles";
import { PDFViewerActionsProvider } from "components/PDFViewer/PDFViewerActionContext";
import { useContractQuery, useFetchDocumentsQuery } from "shared/api";
import { ContractDetailsProvider, viewModeType } from "./context";
import { AcceptedFileType } from "shared/enums/document.enum";
import { FormProvider, useForm } from "react-hook-form";
import { Loader } from "components";
import { getBackNavigationUrl } from "./helpers";

const Contract = () => {
  const {
    permissionSet,
    setSelectedTeam,
    selectedTeamId,
    organizationId,
    hasFeature,
  } = useTeam();
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const { data: fields } = useFieldsQuery(organizationId);
  const location = useLocation();
  const { id, mode: routeParamMode } = useParams();
  const [searchParams] = useSearchParams();
  const modeAsSearchParam = searchParams.get("mode");
  const mode =
    routeParamMode === viewModeType.EDIT ||
    modeAsSearchParam === viewModeType.EDIT
      ? viewModeType.EDIT
      : viewModeType.VIEW;

  const [contractFileBlob, setContractFile] = useState<Blob>();
  const [contractGroup, setContractGroup] = useState<
    ContractNameDto[] | ContractAttachmentListItemDTO[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: contract, refetch } = useContractQuery(selectedTeamId, id);
  const navigate = useNavigate();
  const [isContractDataFetching, setIsContractDataFetching] = useState(false);
  const printRef = useRef(null);
  //TODO: to be update with documentId from route
  // using this to get the list of documents, to access the first document for downloading the file
  const { data: documents } = useFetchDocumentsQuery(
    organizationId,
    contract?.teamId,
    id
  );
  const [firstDocument, setFirstDocument] = useState<DocumentDTO>();

  const editable = mode === "edit";

  const category = useMemo(
    () => categories?.find((category) => category.id === contract?.categoryId),
    [categories, contract]
  );

  const methods = useForm<ContractDTOV1>({
    defaultValues: { ...contract, fields: {} },
  });

  const fetchData = async () => {
    await refetch();
    await fetchContractGroup();
  };

  const fetchContractGroup = async () => {
    if (!contract) {
      return;
    }
    try {
      setIsContractDataFetching(true);
      setIsLoading(false);
      const updatedContract = await OrganizationService.getContractOfTeamById(
        selectedTeamId,
        contract.id
      );
      const contractGroup = await ContractAttachmentService.getContractsOfGroup(
        updatedContract.parentId ?? updatedContract.id
      );
      setContractGroup(contractGroup);
      await fetchPdf();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setIsContractDataFetching(false);
    }
  };

  useEffect(() => {
    void fetchPdf();
  }, [documents, contract]);

  const fetchPdf = async () => {
    if (!documents?.length || !contract) {
      setContractFile(undefined);
      return;
    }
    const firstDocument = documents[0];
    setFirstDocument(firstDocument);
    const blob = (await DocumentService.downloadDocument(
      organizationId,
      contract?.teamId,
      contract.id,
      firstDocument.id
    )) as Blob;

    setContractFile(blob);
  };

  useEffect(() => {
    void fetchContractGroup();
  }, [contract?.id]);

  useEffect(() => {
    if (contract && !permissionSet?.[contract.teamId]) {
      void setSelectedTeam(contract.teamId);
    }
  }, [contract]);

  const handleNavigateBack = () => {
    const newUrl = getBackNavigationUrl(location.pathname, searchParams);
    navigate(newUrl);
  };

  const handleClick = () =>
    editable ? handleNavigateBack() : navigate(routePaths.CONTRACTS);

  if (isLoading) return <Loader />;

  return (
    <ContractDetailsProvider>
      <PrintProvider title={contract?.name}>
        <div>
          <CircularLoading isLoading={isLoading} />
          {contract && category && fields && (
            <>
              <Header
                editable={editable}
                handleClick={handleClick}
                toolbar={
                  <Toolbar
                    contract={contract}
                    fetchData={fetchData}
                    fetchPdf={fetchPdf}
                    contractGroup={
                      contractGroup as ContractAttachmentListItemDTO[]
                    }
                    contractFile={contractFileBlob}
                    firstDocumentId={firstDocument?.id}
                    editable={editable}
                  />
                }
              />
              <PDFViewerActionsProvider>
                <FormProvider {...methods}>
                  <ContractGrid>
                    <ContractColumn>
                      {hasFeature(Features.CONTRACT_ANALYSIS) && (
                        <VerifyAllBanner
                          contract={contract}
                          refetch={fetchData}
                        />
                      )}
                      <OverlayScrollbarsComponent
                        defer
                        style={{ maxHeight: "100%" }}
                      >
                        <ContractData
                          isLoading={isLoading}
                          isCategoryDisabled={
                            contractFileBlob?.type === AcceptedFileType.LEXICAL
                          }
                          contract={contract}
                          isContractDataFetching={isContractDataFetching}
                          category={category}
                          fields={fields}
                          fetchData={fetchData}
                          contractGroup={contractGroup}
                          ref={printRef}
                        />
                      </OverlayScrollbarsComponent>
                    </ContractColumn>
                    <ContractColumn>
                      <RightSide
                        fetchData={fetchData}
                        fetchPdf={fetchPdf}
                        contract={contract}
                        contractGroup={
                          contractGroup as ContractAttachmentListItemDTO[]
                        }
                      />
                    </ContractColumn>
                  </ContractGrid>
                </FormProvider>
              </PDFViewerActionsProvider>
            </>
          )}
        </div>
      </PrintProvider>
    </ContractDetailsProvider>
  );
};

export default Contract;
