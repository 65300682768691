export enum Features {
  ESIGNATURE = "esignature",
  IMPORT_VIA_EMAIL = "import-via-email",
  CONTRACT_CUSTOMIZATION = "contract-customization",
  IMPORT_CSV = "import-csv",
  EXCEL_EXPORTS = "excel-exports",
  PHONE_SUPPORT = "phone-support",
  GUIDED_ONBOARDING = "guided-onboarding",
  PERSONAL_ACCOUNT_MANAGER = "personal-account-manager",
  USER_MANAGEMENT = "user-management",
  SUB_TEAMS = "subteams",
  BACKUP = "backup",
  CONTRACT_TEMPLATES = "contract-templates",
  SSO = "sso",
  DELEGATED_SSO = "delegated-sso",
  IMPORT_VIA_INTEGRATION = "import-via-integration",
  EID_ESIGNATURE = "eid-esignature",
  ESIGNATURE_PPU = "esignature-payperuse",
  EID_ESIGNATURE_PPU = "eid-esignature-payperuse",
  WEBHOOK = "webhook",
  TASK_AUTOMATION = "task-automation",
  PUBLIC_API = "public-api",
  CONTRACT_ANALYSIS = "contract-analysis",
  CONTRACT_CHANGE_LOG = "contract-change-log",
  PERMISSION_CHANGE_LOG = "permission-changelog",
  MULTIPLE_DOCUMENT = "multiple-documents",
  BULK_DOWNLOAD = "bulk-download",
  DOCUMENT_MIGRATION = "document-migration",
}
