import styled from "@emotion/styled";
import { theme } from "theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const FormButtonsHeight = "40px";
export const Wrapper = styled.div`
  margin-top: ${theme.spacing.xl};
`;

export const Form = styled.form`
  margin: ${theme.spacing.sm} 0;
`;

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing.md};
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  margin-top: -${FormButtonsHeight};
`;

export const Name = styled.div`
  font-size: ${theme.font.size.body};
  font-weight: ${theme.font.weight.bold};
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.lg};
  border: 2px solid ${theme.color.red[400]};
  border-radius: ${theme.borderRadius};
  background: ${theme.color.red[200]};
`;

export const AlertText = styled.div`
  flex: 1 1 auto;
  & h3 {
    margin: 0;
  }
`;

export const AlertIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${theme.spacing.xxxxl};
  height: ${theme.spacing.xxxxl};
  margin-right: ${theme.spacing.xl};
  flex: 0 0 auto;
  background: ${theme.color.red[600]};
`;

export const Row = styled.div<{
  alignItems?: string;
  justifyItems?: string;
  maxWidth?: string;
  gap?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyItems || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  max-width: ${(props) => props.maxWidth || "100%"};
  gap: ${(props) => props.gap || theme.spacing.md};
`;

export const ButtonWithIcon = styled(LoadingButton)`
  background: none;
  color: ${theme.color.blue[600]};
  &:hover {
    background: ${theme.color.blue[100]};
  }
  & svg {
    fill: ${theme.color.blue[600]};
  }
`;
export const CategoryListContainer = styled(OverlayScrollbarsComponent)`
  overflow-y: auto;
  max-height: calc(100vh - 560px);
  margin-bottom: 1rem;
  padding-bottom: ${FormButtonsHeight};
  @media (max-height: 700px) {
    max-height: 100%;
  }
`;

export const TeamsSettingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;
