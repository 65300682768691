import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Header, Title, Top, Bottom, SubTitle, Buttons } from "./styles";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import DocumentListItem from "../../../../new-components/DocumentListItem/DocumentListItem";
import { useTranslation } from "react-i18next";
import { CTAButton } from "components";
import SearchBar from "./components/SearchBar";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ContractAttachmentListItemDTO, ContractDTOV1 } from "openapi";
import { useNavigate, useParams } from "react-router";
import { getNavigationToContractViewById } from "constants/utils";
import { useTeam } from "contexts/team/hooks";
import ChangeRelationType from "pages/Contract/components/Related/components/ChangeRelationType";
import DeleteContract from "pages/Contract/components/Related/components/DeleteContract/DeleteContract";
import { Tag } from "new-components";
import { useOrganizationCategoriesQuery } from "shared/api";
import { categoriesTeamSelector } from "shared/api/organization/categories.helpers";
import Dropdown from "./components/Dropdown/Dropdown";

type Props = {
  contractGroup: ContractAttachmentListItemDTO[];
  contractData?: ContractDTOV1;
  fetchPdf: (loadFromStash?: boolean | undefined) => Promise<void>;
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
};

const Related = ({
  contractGroup,
  contractData,
  fetchPdf,
  fetchData,
}: Props) => {
  const { t } = useTranslation();
  const { hasWriteAccess, organizationId, selectedTeamId } = useTeam();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [filteredRelated, setFilteredRelated] = useState<
    ContractAttachmentListItemDTO[]
  >([]);

  const handleSearch = () => {
    setShowSearch((prevProps) => !prevProps);
  };

  const mainContract = useMemo(() => {
    return contractGroup.find((item) => item.parentId === null);
  }, [contractGroup]);

  const relatedList = useMemo(() => {
    return contractGroup
      .filter((item) => item.parentId === mainContract?.id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [contractGroup]);

  useEffect(() => {
    setFilteredRelated(relatedList);
  }, [relatedList, showSearch]);

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filteredItems = relatedList?.filter((item) =>
      item?.name?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (filteredItems) setFilteredRelated(filteredItems);
  };

  const onRelatedClick = (contractId: string) => {
    navigate(getNavigationToContractViewById(contractId));
  };

  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (categories) =>
      categories.filter((category) =>
        categoriesTeamSelector(category, contractData?.teamId || selectedTeamId)
      )
  );

  const getCategories = useCallback(
    (categoryId?: string) => {
      if (!categories || !categoryId) return;

      return categories?.find((category) => category.id === categoryId);
    },
    [categories]
  );

  const mainContractCategory = getCategories(mainContract?.categoryId);

  const numberOfRelated = filteredRelated?.length;

  return (
    <>
      <Header>
        <Top>
          <Title>
            <span>{t("pages.contractDetails.folders.related")}</span>
          </Title>
          <Buttons>
            {hasWriteAccess(contractData?.categoryId, contractData?.teamId) && (
              <Dropdown
                fetchData={fetchData}
                contractData={contractData}
                contractGroup={contractGroup}
                fetchPdf={fetchPdf}
              />
            )}
            <CTAButton
              variant="tertiary"
              icon={<SearchOutlinedIcon />}
              size="tiny"
              onClick={handleSearch}
            />
          </Buttons>
        </Top>

        {showSearch && (
          <Bottom>
            <SearchBar onChange={onFilterChange} />
          </Bottom>
        )}
      </Header>

      <OverlayScrollbarsComponent
        defer
        style={{
          overflowX: "auto",
        }}
      >
        <>
          {mainContract && (
            <>
              <SubTitle>
                {t("pages.contractDetails.related.mainContract")}
              </SubTitle>
              <DocumentListItem
                listItem={mainContract}
                category={mainContractCategory}
                onClick={() => onRelatedClick(mainContract.id)}
                active={id === mainContract.id}
                authorName={mainContract.author}
                label={
                  <Tag variant="main-contract">
                    {t("contractType.MAIN_CONTRACT")}
                  </Tag>
                }
                buttons={
                  <DeleteContract
                    listItem={mainContract}
                    contractGroup={contractGroup}
                  />
                }
              />
            </>
          )}
          {filteredRelated && (
            <div aria-label="related-documents-list">
              <SubTitle>
                {t("pages.contractDetails.related.relatedContracts", {
                  numberOfRelated,
                })}
              </SubTitle>
              {filteredRelated.map((attachment) => {
                const isActive = id === attachment.id;
                const hideRelatedButton =
                  (mainContract && !hasWriteAccess(mainContract.categoryId)) ||
                  !hasWriteAccess(attachment.categoryId);

                const refetchOnDelete = async () => {
                  if (!isActive) await fetchData();
                };

                const category = getCategories(attachment.categoryId);
                return (
                  <DocumentListItem
                    key={attachment.id}
                    listItem={attachment}
                    category={category}
                    onClick={() => onRelatedClick(attachment.id)}
                    active={isActive}
                    authorName={attachment.author}
                    buttons={
                      <>
                        {!hideRelatedButton && (
                          <ChangeRelationType
                            refetchData={fetchData}
                            contractId={attachment.id}
                          />
                        )}
                        <DeleteContract
                          listItem={attachment}
                          contractGroup={contractGroup}
                          refetchOnDelete={refetchOnDelete}
                        />
                      </>
                    }
                    label={
                      <Tag variant="related">
                        {t("contractType.ATTACHMENT")}
                      </Tag>
                    }
                  />
                );
              })}
            </div>
          )}
        </>
      </OverlayScrollbarsComponent>
    </>
  );
};

export default Related;
