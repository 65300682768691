import React, { useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FILL_PLACEHOLDER_COMMAND } from "../../plugins/TemplatePlugin";
import { getFieldIcon } from "pages/Contract/components/ContractChangeLog/components/LogEntity/LogEntity";
import { Input, IconWrapper, TemplateComponentWrapper, Name } from "./styles";
import { TextEditorField } from "new-components/TextEditor/TextEditor";

export type TemplateComponentProps = {
  draggable?: boolean;
  datapoint: TextEditorField;
};

const PlaceholderComponent = (props: TemplateComponentProps) => {
  const { draggable, datapoint } = props;
  // move all lexical functionality in upper component / edit functionality as well
  const [editor] = useLexicalComposerContext();

  const [isDragging, setIsDragging] = useState(false);

  const [editable, setEditable] = useState(false);

  const fieldIcon = getFieldIcon(datapoint.type);

  return (
    <TemplateComponentWrapper
      draggable={draggable}
      onDragStart={(e) => {
        e.dataTransfer.setData("text/plain", JSON.stringify(props));
        e.dataTransfer.effectAllowed = "copy";
      }}
      contentEditable={false}
      onDoubleClick={() => {
        setEditable(true);
      }}
      onDrag={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      isDragging={isDragging}
    >
      <IconWrapper>{fieldIcon}</IconWrapper>
      <Name>{datapoint.display}</Name>
      {editable && (
        <Input
          type="text"
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              editor.dispatchCommand(FILL_PLACEHOLDER_COMMAND, {
                origin: "internal",
                slug: datapoint.visibleId || "",
                fieldKey: datapoint.field,
                value: e.currentTarget.value,
              });
              setEditable(false);
            }
          }}
        />
      )}
    </TemplateComponentWrapper>
  );
};
export default PlaceholderComponent;
