import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: calc(var(--spacing-lg) * -1);
  padding: var(--spacing-sm) var(--spacing-lg);
  margin-bottom: var(--spacing-lg);
  background-color: var(--color-white);
  border-bottom: 1px solid ${theme.color.gray[300]};
  min-height: 61px;
`;

export const ActionButtons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.spacing.md};
  padding-left: var(--spacing-md);

  &:before {
    content: "";
    position: absolute;
    height: calc(100% + var(--spacing-lg));
    width: 1px;
    top: 50%;
    left: 0;
    background-color: ${theme.color.gray[300]};
    transform: translateY(-50%);

    @media (max-width: 1200px) {
      display: none;
    }
  }
`;
