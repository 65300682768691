import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -1rem;
  padding: var(--spacing-sm) var(--spacing-lg);
  margin-bottom: 1.5rem;
  background-color: var(--color-white);
  border-bottom: 1px solid ${theme.color.gray[300]};
`;

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1400;
`;
