import styled from "@emotion/styled";
import CustomCardWrapper from "components/CardWrapper/CardWrapper";
import { theme } from "theme";

export const Wrapper = styled.div`
  width: 100%;
`;

export const CardWrapper = styled(CustomCardWrapper)`
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
`;

export const Container = styled.div`
  background-color: var(--color-white);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  box-shadow: ${theme.shadow.standard};
  height: 100%;
`;
