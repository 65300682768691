import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { t } from "i18next";
import { ContractFieldDTOV1 } from "openapi";
import { Language } from "shared/i18n/i18n";
import globalConfig from "shared/config/global.config";

export const getErrorKey = (url: string): string => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

export function mergeRefs<T = unknown>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

export const isDatePast = (date: string | undefined | null) => {
  const today = dayjs().startOf("day");
  return dayjs(date).isBefore(today, "day");
};

export const getDaysUntilDate = (
  date: string | undefined | null,
  daysUntilDate: number
) => {
  if (date === undefined || null) return undefined;

  const today = dayjs().startOf("day");
  const dateToCheck = dayjs(date);
  const daysDifference = dateToCheck.diff(today, "day");

  if (daysDifference >= 0 && daysDifference <= daysUntilDate) {
    return daysDifference;
  } else {
    return undefined;
  }
};

export const getLanguageOptions = () => {
  const languagesArray = Object.keys(Language);
  return languagesArray?.map((item) => {
    return { label: t(`common.languages.${item}`), value: item };
  });
};

export const scaleLocaleDecimalNumber = (
  value: number,
  decimalScale: number,
  locale: string
): string => {
  const rounded = value.toFixed(decimalScale);
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalScale,
  }).format(+rounded);
};

export const isDevelopment = () => {
  const env = globalConfig.REACT_APP_ENV;
  if (!env) {
    return true;
  }

  return !(env === "staging" || env === "production");
};

export const getMatchingField = (
  fields: ContractFieldDTOV1[],
  fieldId: string,
  teamId: string
) => {
  const field = fields.find((item) => item.id === fieldId);
  if (field?.teamId === teamId || field?.organizationId !== null) {
    return field;
  }
  return fields.find(
    (item) =>
      item.teamId === teamId &&
      item.name["de"] === field?.name["de"] &&
      item.type === field?.type
  );
};

type DynamicField = {
  value: string | number;
};

const isDynamicField = (value: DynamicField) => {
  return value && typeof value === "object" && "value" in value;
};

export const getValue = (field: string | DynamicField): string | undefined => {
  if (typeof field === "string") {
    return field.trim();
  }

  if (isDynamicField(field)) {
    return field.value.toString().trim();
  }

  if (Sentry) {
    Sentry.captureMessage(`Invalid field type: ${JSON.stringify(field)}`);
  }

  return undefined;
};

export const getCssVariableInPx = (variable: string) => {
  const value = getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();

  if (value.endsWith("rem")) {
    return (
      parseFloat(value) *
      parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  }

  return parseFloat(value) || 0;
};
